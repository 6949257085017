import { Component, HostListener, OnInit, } from "@angular/core";


@Component({
  selector: "app-onepage",
  templateUrl: "./onepage.component.html",
  styleUrls: ["./onepage.component.scss"],

})


export class OnepageComponent implements OnInit {
  showNav: boolean;

  ngOnInit() {

  }
  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    //console.log(window.scrollY);
    if (window.scrollY < 200) {
      this.showNav = false
    } else {
      this.showNav = true
    }
  }
}

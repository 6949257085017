import { Injectable } from '@angular/core';
import { User } from './user.model';
import { Observable, of } from 'rxjs';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

let USERS: User[] = [
  {
    _id: "9827534091745",
    title: "Administrator",
    username: "admin",
    password: "admin",
    url: "https://drive.google.com/embeddedfolderview?id=1A1YjETJ4-W7hpRKssq-UuGG9lHGrisbr#list"
  },
  {
    _id: "9827534091746",
    title: "Homestead Shared",
    username: "homestead",
    password: "recording",
    url: "https://drive.google.com/embeddedfolderview?id=1A1YjETJ4-W7hpRKssq-UuGG9lHGrisbr#list"
  },
  {
    _id: "9827534091744",
    title: "Artist",
    username: "artist",
    password: "artist",
    url: "https://drive.google.com/embeddedfolderview?id=10h4XFRzQrsiaw9fFaJpDdVTb8HTdq-DW#list"
  }
];


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }

  //SEARCHING LOCAL DATA FOR USER LOGIN INFO | LOCAL VALIDATION
  public login(user: User): boolean {
    let selectedUser = USERS.find(u => u.username === user.username);
    if (selectedUser === undefined) {
      alert("Login Failed. Please check your username and password.");
    }
    else if (selectedUser.password === user.password) {
      localStorage.setItem('status', 'true');
      return true;
    } else {
      alert("Login Failed. Please check your username and password.");
      return false;
    }
  }

  //FUNCTIONS FOR LOCAL DATA
  public getUsers(): User[] {
    return USERS
  }
  public addUser(user: User) {
    USERS.push(user);
  }
  public deleteUser(username: string) {
    USERS = USERS.filter(u => u.username != username);
    return USERS;
  }

  //FUNCTIONS FOR API CALLS
  public apilogin(user: User) {
    this.getApiUsers().subscribe(data => {
      let selectedUser = data.find(u => u.username === user.username);

      if (selectedUser === undefined) {
        alert("Login Failed. Please check your username and password.");
      }
      else if (selectedUser.password === user.password) {

        localStorage.setItem('status', 'true');
        localStorage.setItem('url', selectedUser.url)
        this.router.navigate(["/files", selectedUser.username])
      }
      else {
        alert("Login Failed. Please check your username and password.");
      }

    }, error => {
      alert('Login Failed. Contact for proper credentials.')
    });
  }

  public getApiUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>(environment.GET_API_URL);
  }
  public deleteApiUser(username: string) {
    //console.log(username)
    return this.httpClient.delete(environment.DELETE_API_URL + '?username=' + username);
  }
  public addApiUser(user: User): Observable<User> {
    //console.log(user);
    return this.httpClient.post<User>(environment.POST_API_URL, user, httpOptions);
  }
}


import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { User } from 'src/app/user.model';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  public user: User = {
    _id: "",
    title: "",
    username: "",
    password: "",
    url: ""
  }

  constructor(
    private userService: UserService,
    private router: Router) { }

  ngOnInit() {
  }

  public apilogin() {
    this.userService.apilogin(this.user);
    localStorage.setItem('currentUser', JSON.stringify(this.user.username));

  }

  public fakelogin() {
    alert('Wrong Username or Password')
  }

  public login() {
    let status = this.userService.login(this.user);
    if (status) {
      localStorage.setItem('currentUser', JSON.stringify(this.user.username));

      if (this.user.username === "admin") {
        alert('Administrator Login')
        this.router.navigate(["/files", this.user.username])
      }
      else this.router.navigate(["/files", this.user.username])
    }
  }
}
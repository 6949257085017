import { Component, OnInit } from "@angular/core";

@Component({
  selector: "splash-screen",
  templateUrl: "./splash-screen.component.html",
  styleUrls: ["./splash-screen.component.scss"]
})
export class SplashScreenComponent implements OnInit {
  opacity: number;
  showSplash = true;

  ngOnInit(): void {
    setTimeout(() => {
      this.showSplash = !this.showSplash;
    }, 5000)
  }

  //   function increment() {
  //     let i = 1

  //     while (i > 0.1) {
  //       i = i - 0.1;
  //       this.opacity = i
  //       console.log(this.opacity)
  //     }
  //   }
  //   setInterval(increment, 100000)
  // }


  //   setTimeout(() => {
  //     //this.windowHeight = "-" + window.innerHeight + "px";
  //     this.windowHeight
  //     setTimeout(() => {
  //       this.showSplash = !this.showSplash;
  //     }, 500);
  //   }, 2000);
  // }


}
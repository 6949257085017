import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss']
})
export class RatesComponent implements OnInit {

  total: number;
  hourly: number;

  total1: number;
  mix: number;

  total2: number;
  master: number;

  total3: number;

  calculatehourly(hours: number) {
    let hourly = 40;
    if (+hours < 0) {
      alert("Please enter a positive number.")
    }
    else {
      this.total = +hours * hourly;
    }
  }

  calculatemix(songsmix: number) {
    let mix = 150;
    if (+songsmix < 0) {
      alert("Please enter a positive number.")
    }
    else {
      this.total1 = +songsmix * mix;
    }
  }

  calculatemaster(songsmaster: number) {
    let master = 35;
    if (+songsmaster < 0) {
      alert("Please enter a positive number.")
    }
    else {
      this.total2 = +songsmaster * master;
    }
  }
  calculatetotal(total: number, total1: number, total2: number) {
    if (total === undefined) {
      total = 0;
    }
    if (total1 === undefined) {
      total1 = 0;
    }
    if (total2 === undefined) {
      total2 = 0;
    }
    this.total3 = total + total1 + total2;
  }

  constructor() { }

  ngOnInit() {

  }

}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { OnepageComponent } from "./components/onepage/onepage.component";
import { LoginComponent } from "./pages/login/login.component";
import { FilesComponent } from './pages/files/files.component';
import { AuthGuardService } from './auth-guard.service';
import { MoreComponent } from './pages/more/more.component';
import { ShopComponent } from "./pages/shop/shop.component";
import { DiscographyComponent } from "./pages/discography/discography.component";

const routes: Routes = [
  { path: "", component: OnepageComponent },
  { path: "home", component: OnepageComponent },
  // { path: "login", component: LoginComponent },
  { path: "more", component: MoreComponent },
  { path: "discography", component: DiscographyComponent },
  // { path: "shop", component: ShopComponent },
  //{ path: "files/:id", component: FilesComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
declare let gtag: Function;
@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit {

  constructor() { }

  onPlay(songInfo: string) {
    //console.log('play', songInfo)
    // gtag('event', 'play', {
    //   'event_category': 'audio',
    //   'song_info': songInfo
    // });
    gtag('event', songInfo);
  }
  //onPause() {
  // console.log('pause')
  //}
  ngOnInit() {

  }

}

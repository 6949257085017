import { Component, OnInit } from '@angular/core';
import { style, state, animate, transition, trigger } from '@angular/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@Component({
  selector: 'app-newhome',
  // https://angular.io/guide/transition-and-triggers
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('2s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('2s', style({ opacity: 0 }))
      ])
    ]),
  ],
  templateUrl: './newhome.component.html',
  styleUrls: ['./newhome.component.scss']
})
export class NewhomeComponent implements OnInit {

  show: boolean = true;
  buttonName: string = 'Mobile';

  showTitleMask: boolean = false;
  showAboutMask: boolean = false;
  showGearMask: boolean = false;
  showWorkMask: boolean = false;
  showContactMask: boolean = false;
  showMoreMask: boolean = false;

  constructor() {


  }

  ngOnInit() {
  }

  toggle() {
    this.show = !this.show;

    if (this.show)
      this.buttonName = "Mobile";
    else
      this.buttonName = "Desktop";
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public show: boolean = false;
  public buttonName: any = 'Show';

  public showOverlay: boolean = false;

  public time: any = new Date()
  public copyrightDate: any = this.time.getFullYear()

  constructor() {
    // this.showOverlay = false;
  }


  ngOnInit() {
  }

  toggle() {
    this.show = !this.show;

    if (this.show)
      this.buttonName = "Hide";
    else
      this.buttonName = "Show";
  }

}

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./pages/home/home.component";
import { AboutComponent } from "./pages/about/about.component";
import { GearComponent } from "./pages/gear/gear.component";
import { WorkComponent } from "./pages/work/work.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { OnepageComponent } from "./components/onepage/onepage.component";
import { LoginComponent } from './pages/login/login.component';
import { FilesComponent } from './pages/files/files.component';
import { MoreComponent } from './pages/more/more.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FileManagerAllModule } from '@syncfusion/ej2-angular-filemanager';
import { RatesComponent } from './pages/rates/rates.component';
import { HttpClientModule } from '@angular/common/http';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { NewhomeComponent } from './pages/newhome/newhome.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShopComponent } from './pages/shop/shop.component';
import { DiscographyComponent } from "./pages/discography/discography.component";

@NgModule({
  declarations: [
    AppComponent,
    SplashScreenComponent,
    HomeComponent,
    NewhomeComponent,
    AboutComponent,
    GearComponent,
    WorkComponent,
    ContactComponent,
    OnepageComponent,
    LoginComponent,
    FilesComponent,
    RatesComponent,
    MoreComponent,
    NewhomeComponent,
    DiscographyComponent,
    // ShopComponent,
  ],
  imports: [FormsModule, BrowserModule, FileManagerAllModule, AppRoutingModule, RouterModule, AppRoutingModule, HttpClientModule, BrowserAnimationsModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

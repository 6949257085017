import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/user.model';
import { UserService } from 'src/app/user.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {

  public url: String = localStorage.getItem('url');
  public user: String = (JSON.parse(localStorage.getItem('currentUser')));
  public admin: Boolean = false;
  public client: Boolean = false;
  public urlSafe: SafeResourceUrl;

  public userList = this.userService.getUsers();

  public apiuserList: User[] = [];

  public currentUserInfo = this.apiuserList.filter(u => u.username === this.user);
  public newUser: User = new User();
  public username: string = "";
  public selectDelete: string = "";

  constructor(
    public userService: UserService,
    public sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (this.user === "admin") {
      this.admin = true;
    } else {
      this.client = true;
    }
    //SUBSCRIBE TO API USER LIST | EXPRESS | LOCALHOST:3000
    this.userService.getApiUsers().subscribe(
      data => {
        this.apiuserList = data;
      }
    )
  };

  public logout() {
    localStorage.removeItem('status');
    localStorage.removeItem('currentUser');
  }

  public addUser() {
    if (this.newUser.title == null) {
      alert("title is blank")
    }
    if (this.newUser.username == null) {
      alert("username is blank")
    }
    if (this.newUser.password == null) {
      alert("password is blank")
    }
    if (this.newUser.url == null) {
      alert("drive url is blank")
    }
    else
      this.userService.addUser(this.newUser);
    this.newUser = new User();
  }

  public displayurl(url: string) {
    alert(url);
  }

  public dropdownValue() {
    this.selectDelete = this.username;
  }

  public deleteUser() {
    this.userService.deleteUser(this.selectDelete);
    this.userList = this.userService.getUsers();
  }

  //API CALL FUNCTIONS
  public deleteApiUser() {
    this.userService.deleteApiUser(this.selectDelete).subscribe(data => {
      this.userService.getApiUsers().subscribe(
        data => {
          this.apiuserList = data;
        }
      )
      //refresh list

    },

      error => {
        this.userService.getApiUsers().subscribe(
          data => {
            this.apiuserList = data;
          }
        )
        // console.log(this.apiuserList)
      })
  }

  public addApiUser() {
    if (this.newUser.title == null && " ") {
      alert("title is blank")
    }
    if (this.newUser.username == null) {
      alert("username is blank")
    }
    if (this.newUser.password == null) {
      alert("password is blank")
    }
    if (this.newUser.url == null) {
      alert("drive url is blank")
    }
    else {
      this.userService.addApiUser(this.newUser).subscribe(data => {
        this.userService.getApiUsers().subscribe(
          data => {
            this.apiuserList = data;
          }
        )
        //refresh list
      },
        error => {
          this.userService.getApiUsers().subscribe(
            data => {
              this.apiuserList = data;
            }
          )
          // console.log(this.apiuserList)
        })
      this.newUser = new User();
    }
  }
}